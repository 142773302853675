
a{
  color: #94ADA7!important;
  text-decoration: none!important;
}
body{
  --mantine-font-size-xs: 0.72rem!important;
}


@keyframes fadeInPsc {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 0.7;
    transform: translateY(0);
  }
}

@keyframes fadeInLogin {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes heartbeat-animation {
  0% {
    opacity:1;
    transform: translateY(0px);
  }
 
  50% {
    opacity:1;
    transform: translateY(5px);
  }
  
  100% {
    opacity:1;
    transform: translateY(0);
  }
}

@keyframes fadeInRotate {
  from {
    opacity: 0;
    transform: translateY(-45px);
  }
  to {
    opacity: 0.9;
    transform: translateY(0);
    transform: rotate(6deg);z-index:-2;
  }
}

@keyframes fadeInRotate2 {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
    transform: rotate(6deg);z-index:-2;
  }
}



@keyframes fadeInTranslateX {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
   
  }
}

@keyframes fadeInTranslateY {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
   
  }
}


@media (min-width: 601px)  {
  .ArrierePlan{
    position:absolute;
    height:600px!important;
    width:600px!important;
    left:-80px!important;
    top:-80px!important;
    transform: rotate(6deg);
    z-index:-2!important;
    animation: fadeInRotate2 1.5s ease-in-out;
  }

  .ArrierePlanBrain{
    position:absolute;
    height:200px!important;
    width:200px!important;
    left:80px!important;
    top:140px!important;
    transform: rotate(6deg);z-index:-2;
    opacity:0.9;
    animation: fadeInRotate 1.5s ease-in-out;
  }

  .homeTitle{
    animation: fadeInTranslateX 1.5s ease-in-out;
    left: 270px;
    top: 100px;
  }

  .homeTitle-2{
    animation: fadeInTranslateY 1.5s ease-in-out;
    left: 300px;
    top: 177px;
  }

  .loginCard {
    position:absolute;
    left:40%;
    top:35%;
    translate:middle!important;
    padding:30px;
    z-index:3000;
    background:transparent!important;
    border: none;
  }

  .PSC-button{
    opacity: 0; /* Commence invisible */
    margin:10px;
    cursor:pointer;
    z-index:3000;
    height:fit-content!important;
    width:it-content!important;
    padding:10px!important;
    transition: box-shadow .25s ease-out,transform .25s ease-out,opacity .4s ease-out;
    border: solid 1px #7a969085;
    animation: fadeInPsc 1s ease-in-out;
    animation-delay: 1s; /* Délai avant le début de l'animation */
    animation-fill-mode: forwards; /* Garde l'état final après l'animation */
  }

  .PSC-button2{
    opacity: 0.7; /* Commence invisible */
    margin:10px;
    cursor:pointer;
    z-index:3000;
    height:fit-content!important;
    width:fit-content!important;
    padding:10px!important;
    transition: box-shadow .25s ease-out,transform .25s ease-out,opacity .4s ease-out;
    border: solid 1px #7a969085;
  }
  
  
  .loginTitle{
    opacity: 0; /* Commence invisible */
    transform: translateX(100px); /* Commence décalé */
    animation: fadeInLogin 1s ease-out;
    animation-delay: 1s; /* Délai avant le début de l'animation */
    animation-fill-mode: forwards; /* Garde l'état final après l'animation */
  
  }
  
  .arrowLogin{
    position:absolute;
    top:75px!important;
    opacity: 0; /* Commence invisible */
    animation: heartbeat-animation 2s ease-in-out infinite;
    animation-delay: 2s; /* Délai avant le début de l'animation */
    animation-fill-mode: forwards; /* Garde l'état final après l'animation */
  
  }
  
  
  .PSC-button:hover, .PSC-button2:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)!important;
    transform: translateY(-5px);
    transition-duration: .5s;
  }

}



@media (max-width:600px) {
  .ArrierePlan{
    position:absolute;
    left:-10px!important;
    height:300px!important;
    width:300px!important;
    transform: rotate(6deg);
    z-index:-2!important;
    animation: fadeInRotate2 1.5s ease-in-out;
  }

  .ArrierePlanBrain{
    position:absolute;
    height:100px!important;
    width:100px!important;
    left:60px!important;
    top:100px!important;
    transform: rotate(6deg);z-index:-2;
    opacity:0.9;
    animation: fadeInRotate 1.5s ease-in-out;
  }

  .homeTitle{
    font-size:33px!important;
    left:160px!important;
    top:140px!important;
    animation: fadeInTranslateX 1.5s ease-in-out;
  }

  .homeTitle-2{
    font-size:12px!important;
    left:160px!important;
    top:180px!important;
    animation: fadeInTranslateY 1.5s ease-in-out;
  }

  .loginCard {
    position:absolute;
    left:10%;
    top:45%;
    translate:middle!important;
    padding:30px;
    z-index:3000;
    background:transparent!important;
    border: none;
  }
  
  .PSC-button{
    opacity: 0; /* Commence invisible */
    margin:12px;
    cursor:pointer;
    z-index:3000;
    height:fit-content!important;
    width:it-content!important;
    padding:10px!important;
    transition: box-shadow .25s ease-out,transform .25s ease-out,opacity .4s ease-out;
    border: solid 1px #7a969085;
    animation: fadeInPsc 2s ease-in-out;
    animation-delay: 1s; /* Délai avant le début de l'animation */
    animation-fill-mode: forwards; /* Garde l'état final après l'animation */
  }
  
  
  .loginTitle{
    opacity: 0; /* Commence invisible */
    font-size: 15px!important;
    transform: translateX(50px); /* Commence décalé */
    animation: fadeInLogin 1s ease-out;
    animation-delay: 1s; /* Délai avant le début de l'animation */
    animation-fill-mode: forwards; /* Garde l'état final après l'animation */
  
  }
  
  .arrowLogin{
    position:absolute;
    top:46px!important;
    opacity: 0; /* Commence invisible */
    animation: heartbeat-animation 2s ease-in-out infinite;
    animation-delay: 1s; /* Délai avant le début de l'animation */
    animation-fill-mode: forwards; /* Garde l'état final après l'animation */
  
  }
  
  
  .PSC-button:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)!important;
    transform: translateY(-5px);
    transition-duration: .5s;
  }

}



.CVModal{
  z-index: 5000!important;
  --mb-z-index:5000!important;
}

.fc-event .fc-event-main {
  background: #94ADA7!important;
  padding-left:5px;
  vertical-align: middle!important;
}

.fc-button-active, .fc-button-primary {
  background: #94ADA7!important;
  border-color:#94ADA7!important; ;
  color:#fff!important;
  cursor:pointer;
  transition: box-shadow .25s ease-out,transform .25s ease-out,opacity .4s ease-out;
 
}

.connectedButton, .logoutButton, .MessageButton, .AgendaButton, .fullscreenButton,.ObservationButton, .NewObservationButton, .CloseObservationButton,
 .printButton,.hypothesesButton,.propExamenButton, .documentsButton{
  
  /* color: #94ADA7!important; */
  /* border-radius: 14px; */
  border: solid 0.5px  #94ADA7!important;
  padding-left:15px!important;
  padding-right:15px!important;;
  padding:5px;
  
  /* background:white!important; */
  /* opacity:0.9; */
  cursor:pointer;
  transition: box-shadow .25s ease-out,transform .25s ease-out,opacity .4s ease-out;

}

.posologieFieldset{
  cursor:pointer;
  border: solid 1px rgba(74, 123, 73, 0.427) !important;
  box-shadow: var(--mantine-shadow-md);
  transition: box-shadow .25s ease-out,transform .25s ease-out,opacity .4s ease-out;
}

.posologieFieldset:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)!important;
  transform: translateY(-5px);
  transition-duration: .5s;
}

.renouvelerButton, .courrierButton,.TiersButton {
  cursor:pointer;
  transition: box-shadow .25s ease-out,transform .25s ease-out,opacity .4s ease-out;
}



.medicamentsButton,.biologiesButton, .antMedButton, .antChirButton , .antFamButton , .allergiesButton, .documentsButton , .timelineCard,
.mantine-Card-root,   .tttFondButton,.mailButton {
  animation: fadeIn 0.6s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes expand {
  from {
  
  width: 64px;
  }
  to {
    width: 300px;
}
}

@keyframes retract {
  from {
  
    width: 300px;
  }
  to {
    width: 64px;
}
}

@keyframes change {
  from {
    background:#fff;

  }
  to {
    background:#94ADA7;
}
}


.patientSelect{
  animation: retract 0.8s ease-in-out;
  animation-delay: 0.8s;
  animation-fill-mode: forwards; 
  border-radius:16px;
  transition: box-shadow .25s ease-out,transform .25s ease-out,opacity .4s ease-out;


}

.patientSelect:focus-within{
  animation: expand 0.8s ease-in-out;
  animation-fill-mode: forwards; 
 
}

.patientSelect-input{
  background:#fff;
  --input-placeholder-color:#94ADA7 ;
  height: 36px;
  translate:0px 0px;
  border:#94ADA7 1px solid;
  animation: change 0.8s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: forwards; }

.patientSelect-input:focus-within, .patientSelect-input:focus-visible{
  background:#fff!important;
}

.dropdown{
  border-radius: 12px!important;
}

.closeAgendaButton{
  color:grey;
  padding:10px!important;
  position:absolute;
  max-width: fit-content;
  top:-10px;
  left:98%!important;
  cursor:pointer;
}

.connectedButton:hover,  #connectedButton:active ,.logoutButton:hover , .logoutButton:active,  .MessageButton:hover, .AgendaButton:hover ,
 .fc-button-active:hover, .fc-button-primary:hover, .fullscreenButton:hover, .ObservationButton:hover, .NewObservationButton:hover, .CloseObservationButton:hover,
  .printButton:hover, .hypothesesButton:hover, .propExamenButton:hover, .documentsButton:hover, .renouvelerButton:hover, .courrierButton:hover, .patientSelect:hover, .mailButton:hover,.TiersButton:hover {
  /* background: #94ADA7!important;
  color:#fff!important; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)!important;
  transform: translateY(-3px);
  transition-duration: .5s;
}

.antMedButton,.antChirButton, .antFamButton, .allergiesButton, .tttFondButton {
  max-height:29px!important;
}

.medicamentsButton, .biologiesButton {
  max-height:33px!important;
}

.CloseButton{
  position:sticky!important;
  left:100%;
}

.TextButton{
  max-width:500px!important;
}

.fullscreenButton{
  position:absolute!important;
  bottom:1%!important;
  right:1%;
}

.navBar{
  margin:10px!important;
}

.AgendaCard {
justify-content: center;
  position:absolute!important;
  top:10%!important;
  left:5%;
  width:90%;
}

.cps{
  vertical-align:middle;

}

.H_container{
  justify-content:center;
  translate: middle;
  display:flex;
  flex-direction: row;
  gap: 20px;
}

/* Popover Create Event */
.CreateEventPop, .DeleteEventPop {
  position:absolute!important;
  top:40%!important;
  left:40%!important;
  height:165px;
  width:fit-content!important;
  border-radius:16px!important;


}

.historiqueGridCol{
 margin-top: 1%;
 padding-left:1%!important;
 margin-left:0%!important;
 height: 95vh;
 overflow: auto;
}


.antecedentsGridCol{
  margin-top: 1%!important;
  margin-left:0%!important;
  /* padding-right:20px; */
  height: 91vh;
  overflow: hidden;
 }

.ordonnanceGridCol{
  margin-top: 1%;
  margin-left:0%!important;
  padding-left:1%!important;
  padding-right:2%!important;
  height: 95vh;
  overflow: auto;
  
 }

 .MailsGridCol{
  margin-top: 1%!important;
  margin-left:0%!important;
  padding-left:1%!important;
  padding-right:2%!important;
  height: 91vh;
  overflow: hidden;
 }

.timelineCard{
  margin-top:2%;
}

.TimelineCardText{
  margin-left: 2%!important;
  margin-top:1%!important;
}




.m_1f921b3b{
  background-color:transparent!important;
}

.mantine-Tabs-list, .antMedList{
  margin-bottom: 20px;
  margin-top: 10px;
 
}

.patientCard{
  display:flex;
  flex-direction:row!important;
  width:fit-content!important;
  padding:10px;
  margin-top:4%;
  margin-left:5%;
}

.prescriptionsCard{
  height:83vh!important;
}



.antMedButton, .antChirButton, .antFamButton, .allergiesButton, .tttFondButton {
  max-width: 100%;
  border-radius: 12px!important;
  border:solid 0.5px rgba(74, 123, 73, 0.428)!important;
  height:33px!important;
  box-shadow: var(--mantine-shadow-sm)!important;
}

.medicamentsButton, .biologiesButton, .radiologieButton {
  max-width: 100%;
  border-radius: 12px!important;
  border:solid 0.5px rgba(74, 123, 73, 0.428)!important;
  height:39px!important;
  box-shadow: var(--mantine-shadow-sm)!important;
}

.CloseButton{
  position:sticky!important;
  left:100%!important;
}

.MedicamentIcon{
  height:16px!important;
  width:16px!important;
}


.AntecedentsSelect{
  margin-top: 20px!important;
}

.PrescriptionControl{
  margin-bottom: 20px!important;
  
}

.mantine-SegmentedControl-root{
  background-color: white!important;
  border-radius:12px!important;
}

.DocumentControl-label{
  color:"#94ADA7";
}

.historiqueControl{
 
  background-color: white!important;
  border-radius:12px!important;

}

.ModifyAntecedetnsDrawer{
  height:80%!important;
}

.mantine-Select-input{
  border-radius:10px!important;
}

.ChatForm {
  position:absolute!important;
  bottom:5%;
  left:18%;
  width:42.2%!important;
  z-index: 3000;
  box-shadow: var(--mantine-shadow-lg)!important;
  border-radius: var(--mantine-radius-lg)!important ;

}

.ChatTextarea{
  width:100%!important;
  height:10.3vh!important;
}

.ChatTextarea textarea {
  padding-top: 7%!important;;
}
.ChatCard{
  margin-bottom:15%!important;
  margin-top:0%!important;
}

.ChatStreamContainer , .ChatQuestionRecorder{
  height:80%!important;
  min-width:100%!important;
  overflow:auto;
}

.ChatRecorderElements{
  position:absolute!important;
  bottom:11%;
  z-index:3001;
  left:17.8%;
}


.ChatRecorderTextArea {
  position:absolute!important;
  bottom:6.3%;
  left:18%;
  width:42.2%!important;
  z-index: 3000;
  box-shadow: var(--mantine-shadow-lg)!important;
  border-radius: var(--mantine-radius-lg)!important ;
  padding:0%!important;
  background: #fff!important;

}


.toggleChatButton{
  position:absolute!important;
    bottom:12%;
    left:54%;
    z-index:3001;
}


.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

h1 {
  font-size: 24px;
  color: #333;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-container {
  background: transparent!important;
  border:none!important;
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;


}

.editor-inner {
  background: #f1f3f5!important;
  position: relative;
  border-radius: 12px;
}

.editor-input {
  min-height: 150px;
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
  caret-color: #444;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}


.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}


.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}

.editor-heading-h2 {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  text-transform: uppercase;
}


.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.debug-timetravel-button:hover {
  text-decoration: underline;
}

.toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 10px;
  background-size: contain;
}

i.undo {
  background-image: url(/public/icons/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(/public/icons/arrow-clockwise.svg);
}

i.bold {
  background-image: url(/public/icons/type-bold.svg);
}

i.italic {
  background-image: url(/public/icons/type-italic.svg);
}

i.underline {
  background-image: url(/public/icons/type-underline.svg);
}

i.strikethrough {
  background-image: url(/public/icons/type-strikethrough.svg);
}

i.left-align {
  background-image: url(/public/icons/text-left.svg);
}

i.center-align {
  background-image: url(/public/icons/text-center.svg);
}

i.right-align {
  background-image: url(/public/icons/text-right.svg);
}

i.justify-align {
  background-image: url(/public/icons/justify.svg);
}

i.list-ol {
  background-image: url(/public/icons/ordered-list.svg);
}

i.list-ul {
  background-image: url(/public/icons/bullet-list.svg);
}

i.molecule {
  background-image: url(/public/icons/molecule.png);
}

.toolbar select {
  height: 40px;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 9px;
}

#fontSizeSelect {
  border:none;
}

#fontFamilySelect {
  width:70px;
  border:none;
}

.m_a513464::before {
  display:none!important;
}

.transcriptionDialog{
  width:37vw!important;
  max-height: 40vh;
  overflow: auto;
}

.audio-recorder{
background-color: var(--mantine-color-white)!important;
height:calc(2.625rem * var(--mantine-scale))!important;
border-radius:var(--mantine-radius-lg)!important;
box-shadow: none!important;
border:none !important;
/* min-width:60px!important; */
}

.audio-recorder-visualizer canvas,.audio-recorder-visualizer {
  /* Your styles here */
  width: 70px;
  height: 70px;
}

.audio-recorder-mic, .audio-recorder-options {
  opacity:0.35;
  height:20px;
}

.custom-placeholder::placeholder {
  font-size: 12px; /* Ajustez cette valeur selon vos besoins */
}

.audio-recorder-status{
  display:none!important;
}

.audioVisualizer_Observation{
  position:absolute;
  top:0;
  left:50%
}

.audioVisualizer_Chat{
  position:absolute;
  top:-70%;
  left:60%;
}

.audioVisualizer_client{
  position:absolute;
  top:2%;
  left:46%;
 
}
.audioVisualizer_system{
  position:absolute;
  top:2%;
  left:46%;

}

.chatAudio .directchatAudio{
  position:absolute; 
  top:0%!important;
  left:50%;
}

/* HTML: <div class="loader"></div> */
.loaderX {
  width: 18px;
  aspect-ratio: .577;
  color: #54545470;
  display: grid;
  background:
    linear-gradient(currentColor 0 0) top   /100% 1px,
    linear-gradient(currentColor 0 0) bottom/100% 1px,
    linear-gradient(to bottom right, #0000 calc(50% - 2px),currentColor calc(50% - 1px),#0000 50%) top/100% calc(100% + 2px),
    linear-gradient(to bottom left , #0000 calc(50% - 2px),currentColor calc(50% - 1px),#0000 50%) top/100% calc(100% + 2px); 
  background-repeat: no-repeat;
  animation: l17 4s infinite linear;
}
.loaderX::before,
.loaderX::after {
  content: "";
  grid-area: 1/1;
  background: inherit;
  border:inherit;
  animation: inherit;
}
.loaderX::after {
  animation-duration: 2s;
}
@keyframes l17 {
  100% {transform: rotate(1turn)}
}

.mantine-InputWrapper-label{
  display:inline-flex!important;
}

